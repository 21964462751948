import { toast } from "react-toastify";
import cookie from 'js-cookie'
import { FAVORITE_PRODUCT_COOKIE, FAVORITE_SHOP_COOKIE } from "@/constants/General";

export const money = (amount: number) => {

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formatter.format(amount)
}


export const showError = (error: any, alternate: string) => {
    toast.error(error?.data?.message || alternate)
}


export const etherError = (error: string) => {
    try {
        // Donate execption  Error: execution reverted: "Campaign goal already reached" (action="estimateGas",
        if (error.indexOf('execution reverted:') != -1) {
            const startIndex = error.indexOf('execution reverted:') + 'execution reverted:'.length + 2
            const endIndex = error.indexOf('(action', startIndex) - 2
            const result = error.substring(startIndex, endIndex)
            return result.trim()
        } else if (error.indexOf('rejected') != -1) {
            return 'Action canceled'
        }
        return error ?? 'Error performing action, please try again' //error

    } catch (ex) {
        console.log('error extracting error ', ex)
        return error
    }
}

export const calculateSharables = (percents: number[], amount: number) => {
    amount = amount < 1_000_000 ? (amount * 1_000_000) : amount
    let currentPrincipal = amount
    let total = 0

    percents.forEach((p) => {
        var res = Math.floor((p / 100) * currentPrincipal)
        total += res
        currentPrincipal -= res
    })
    return total
}

export const getFavoriteShops = () => {
    try {
        const favs = JSON.parse(cookie.get(FAVORITE_SHOP_COOKIE) ?? '')
        console.log('Favorie', favs)
        return typeof (favs) == 'object' ? favs : JSON.parse(favs)
    } catch (ex) {
        // console.log('Unable to load fav shops cookie', ex)
        return []
    }
}

export const getFavoriteProducts = () => {
    try {
        const favs = JSON.parse(cookie.get(FAVORITE_PRODUCT_COOKIE) ?? '')
        return typeof (favs) == 'object' ? favs : JSON.parse(favs)
    } catch (ex) {
        // console.log('Unable to load fav products cookie', ex)
        return []
    }
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
    const arr = dataurl.split(',')
    const mime = (arr[0].match(/:(.*?);/) ?? '')[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
    // return  new Blob([u8arr], { type: mime });
}


export const computeConversationId = (shop1: Shop, shop2: Shop) => {
    let id = ''
    if (shop1.id < shop2.id) {
        id = `${shop1.id}_${shop2.id}`
    } else {
        id = `${shop2.id}_${shop1.id}`
    }
    return id
}