'use client'
import { allowedChain, chainName, chainRpc, multiple } from "@/constants/Blockchain";
import { BrowserProvider } from "ethers";
import { ethers } from "ethers";
import { toast } from "react-toastify";

import mallArtifact from '../contract/DigitalMallex.json'
import tokenArtifact from '../contract/ReedERC20Token.json'
import { etherError } from "@/utils/General";

const tokenAddress = process.env.NEXT_PUBLIC_TOKEN_ADDRESS ?? ''
export const useBlockChain = () => {

    let provider: BrowserProvider | undefined = undefined

    const getProvider = () => {
        return new ethers.BrowserProvider(window.ethereum, 'any')
    }

    const getAccounts = async () => {
        try {

            if (!window.ethereum) {
                // Metamask or
                toast.error('Please install TrustWallet or Metamask')
                return null
            }
            let prov: BrowserProvider | null = getProvider()

            let network = await prov.getNetwork();

            if (network.chainId != BigInt(allowedChain ?? '')) {
                prov = await handleNetworkDifference()
                if (!prov) return null
            }
            network = await prov.getNetwork();
            // console.log("New network", network)
            // alert(network.name)
            // alert(network.chainId)
            const accounts = await prov.send("eth_requestAccounts", []);
            return accounts;
        } catch (ex: any) {
            if (ex?.message.indexOf('rejected') != -1) {
                toast.error('The request is canceled, please change your network to the requested one')
                return null;
            }
        }
    };

    const getActiveAccount = async () => {
        const accounts = await getAccounts()
        if (!accounts || accounts?.length == 0) return null
        return accounts[0]
    }

    const handleNetworkDifference = async () => {
        //Reqeust network switch
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: allowedChain }],
            });
        } catch (ex: any) {
            // alert(ex.code)
            if (ex?.code == 4100) {
                toast.error(`Please change your network to ${chainName}. You can check the faqs on how to do it`)
            } else {
                toast.error(`Please add the ${chainName} to your wallet to proceed. You can check the faqs on page on how to do that`)
            }
            return null

            // alert(ex)
            // console.log("change network exception ", ex);
            // if (ex?.message.indexOf('rejected') != -1) {
            //     toast.error('The request is canceled, please change your network to the requested one')
            //     return null;
            // }
            //maybe network doesn't exist on the wallet, so ask to add it
            // try {
            //     await window.ethereum.request({
            //         method: "wallet_addEthereumChain",
            //         params: [
            //             {
            //                 chainId: allowedChain,
            //                 chainName: chainName,
            //                 // rpcUrls: [chainRpc],
            //             },
            //         ],
            //     });
            // } catch (error) {
            //     alert(error)
            //     console.log('Add network error ', ex)
            //     toast.error(chainName + ' network not found on your wallet, please add it')
            //     return null
            // }
        }
        let prov = getProvider() //new ethers.BrowserProvider(window.ethereum, "any");
        let network = await prov.getNetwork();
        if (network.chainId != BigInt(allowedChain ?? '')) return null
        return prov
    }

    const getTokenBalance = async (account: string) => {

        // console.log('getting balance ', account)
        // console.log('Token address ', tokenAddress)
        try {
            const prov = getProvider()
            const contract = new ethers.Contract(
                tokenAddress,
                tokenArtifact.abi,
                prov
            );
            const bal = Number(await contract.balanceOf(account))
            // console.log("BAlance ? ", bal)
            return bal > 0 ? bal / multiple : 0
        } catch (ex: any) {
            console.log('Get token error ', ex)
            return null
        }
    }


    const editContract = async (contractAddress: string, data: any[]) => {
        try {

            // console.log('account here ', account)
            const prov = getProvider()
            const signer = await prov.getSigner()

            const contract = new ethers.Contract(
                contractAddress,
                mallArtifact.abi,
                signer
            );
            try {
                const res = await contract.changeContractValues(...data)
                return true;
            } catch (ex: any) {
                const msg = etherError(ex?.message) || 'Error editing contract'
                toast(msg)
                console.log(ex)
            }
            return false
        } catch (ex: any) {
            toast(etherError(ex?.message) || 'Error creating shop')
            return null
        }
    }

    const createShop = async (contractAddress: string, account: string, shopId: number, referer: string) => {
        const prov = getProvider()
        const signer = await prov.getSigner()

        const contract = new ethers.Contract(
            contractAddress,
            mallArtifact.abi,
            signer
        );
        try {
            const res = await contract.createShop(account, referer, shopId.toString())
            return true;
        } catch (ex: any) {
            console.log(ex)
            const msg = etherError(ex?.message) || 'Error creating shop'
            if (msg.trim() == 'This address already have a shop attached to it') return true;
            toast(ex?.message ? msg : ex)
        }
        return false
    }

    const approveCoin = async (contractAddress: string, amount: any) => {
        try {
            const provider = getProvider()
            const signer = await provider.getSigner()
            const contract = new ethers.Contract(tokenAddress, tokenArtifact.abi, signer);
            const balance = await getTokenBalance(signer.address)
            if (balance == null || balance < amount) {
                toast.error('You do not have enough balance for this transaction')
                return false;
            }

            const tx = await contract.approve(contractAddress, ethers.parseUnits(amount.toString(), 6))
            const result = await tx.wait()
            return true
        } catch (ex: any) {
            console.log(ex)
            // alert(ex)
            const msg = etherError(ex?.message)
            // The reason might be duplicate approval, please use the clear approval button and try again
            toast.error(`${msg ? msg : ' Error approving token'}.`)
            return false
        }

    }


    const annualPayment = async (contractAddress: string, account: string) => {
        const prov = getProvider()
        const signer = await prov.getSigner()

        const contract = new ethers.Contract(
            contractAddress,
            mallArtifact.abi,
            signer
        );
        try {
            const res = await contract.payAnnualFee(account, tokenAddress)
            return true;
        } catch (ex: any) {
            console.log(ex)
            // alert(ex)
            const msg = etherError(ex?.message)
            // if (msg.trim() == 'This address already have a shop attached to it') return true;
            toast(msg)
        }
        return false
    }

    const distributionType = ['Transferred', 'InactiveShop', 'NoMoreReferrer']
    const getEarnings = async (contractAddress: string, account: string, type: 'Annual' | 'Monthly') => {
        try {

            const prov = new ethers.JsonRpcProvider('https://polygon-mainnet.g.alchemy.com/v2/oQqrXfBAtd4LWhBDaLeRB0D3PTDG_n4V')

            const contract = new ethers.Contract(
                contractAddress,
                mallArtifact.abi,
                prov
            );

            // Get current block number
            const currentBlockNumber = await prov.getBlockNumber();
            // Calculate block numbers for the last 24 hours (assuming 15 seconds per block)
            const twentyFourHours = currentBlockNumber - (24 * 60 * 60) / 2;

            let filter
            if (type == 'Annual') {
                filter = contract.filters.AnnualFeeDistributed(null, ethers.getAddress(account))
            } else {
                filter = contract.filters.MonthlyFeeDistributed(null, ethers.getAddress(account))
            }

            const logs = await contract.queryFilter(filter, twentyFourHours, currentBlockNumber)
            const earnings: Earning[] = []

            const blockInfoPromises = logs.map(log => prov.getBlock(log.blockHash));
            const blockInfos = await Promise.all(blockInfoPromises);

            // console.log(blockInfos)
            for (let log of logs) {
                const parsedLog = contract.interface.parseLog(log as any);
                if (!parsedLog) continue;

                // Access individual event parameters
                let { showOwner, recipient, level, amount, status } = parsedLog.args;
                amount = Number(amount) / multiple
                level = Number(level)
                status = distributionType[Number(status)]

                // console.log(log.blockNumber)
                const info = blockInfos.find((f) => f?.hash == log.blockHash)
                earnings.push({ shopOwner: showOwner, recipient, level, amount, status, blockhash: log.blockHash, timestamp: info?.timestamp, type })
            }
            return earnings
        } catch (ex) {

        }
        return []
    }

    const registerChainEvent = async () => {
        try {
            window.ethereum.on('chainChanged', (chain: any) => {
                console.log("new chain ", chain)
                window.location.reload()
            })
        } catch (ex) {
            console.log('change changed issue ', ex)
        }
    }

    return { getAccounts, getActiveAccount, createShop, getTokenBalance, approveCoin, annualPayment, editContract, getEarnings, registerChainEvent }

}