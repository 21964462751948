import {
    BaseQueryFn,
    FetchArgs,
    createApi,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

const API_URL = `${process.env.NEXT_PUBLIC_API_URL}/api`  // 'http://localhost:5566/api';

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).auth.token;
        if (token) {
            headers.set('authorization', `Bearer ${token}`);
        }
        headers.set('Accept', 'application/json');

        return headers;
    },
});

const baseQueryWithInterceptor: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    // console.log('api result ==> ', result)
    // if (result.error) {
    //     console.log('api error ==> ', result.error)
    //     console.log(result.meta)
    //     showMessage({ type: 'danger', message: 'Server error, please try again.....', duration: 5000 })
    // }
    return result;
};

export const api = createApi({
    baseQuery: baseQueryWithInterceptor,
    endpoints: () => ({}),
});
